export const layout = {
  desktop: {
    width: '85%',
    height: '110px',
    maxWidth: '1170px',
  },
  block: {
    bottom: '45px',
  },
};
